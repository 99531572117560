import React from "react";
import LabContent from "./LabContent";
import DescriptionArea from "./contents/Description";
import { ILabInfo } from "../models/ILabInfo";
import CicLabContent from "./CicLabContent";

const cardStyles: React.CSSProperties = {
	paddingLeft: "3rem",
	width: "80%",
	minWidth: "1052px", // Set your desired minimum width
	height: "957px",
	backgroundColor: "#ffffff",
	borderRadius: "2px",
	boxShadow: "2px 0px 10px rgba(3,3,3,0.1)",
};
export interface ContentCardProps {
	lab: ILabInfo | undefined;
	workspaceURL: string;
}
const ContentCard = ({ lab, workspaceURL }: ContentCardProps) => {
	// console.log(lab);
	const isCiclab = !!lab?.isCiclab;
	return (
		<div style={cardStyles}>
			<DescriptionArea />
			{/* <LabContent lab={lab} workspaceURL={workspaceURL} /> */}
			{/* <CicLabContent /> */}
			{isCiclab ? (
				<CicLabContent />
			) : (
				<LabContent lab={lab} workspaceURL={workspaceURL} />
			)}
		</div>
	);
};

export default ContentCard;
