import { HttpClient } from "../../httpClient/HttpClient";
import { VerifyAccessCodeWithStudentInfoRequest } from "./requests/VerifyAccessCodeWithStudentInfoRequest";
import { env } from "../../env";

const isValidUrl = (url: string) => {
	return url.includes("oktalearning.com");
};

const REACT_APP_EVENT_MANAGER_ENDPOINT = isValidUrl(
	env.REACT_APP_EVENT_MANAGER_URL
)
	? env.REACT_APP_EVENT_MANAGER_URL
	: "https://eventmanagerdev.oktalearning.com/";

export class EventManagerClient extends HttpClient {
	constructor() {
		super(REACT_APP_EVENT_MANAGER_ENDPOINT);
	}

	handleVerifyAccessCodeWithStudentInfoRequest(
		accesscode: string,
		emailAddress: string,
		firstName: string,
		lastName: string
	): any {
		const request = new VerifyAccessCodeWithStudentInfoRequest({
			accesscode: accesscode,
			emailAddress: emailAddress,
			firstName: firstName,
			lastName: lastName,
		});

		isValidUrl(env.REACT_APP_EVENT_MANAGER_URL);
		console.log(
			"process.env.REACT_APP_EVENT_MANAGER_URL : " +
				env.REACT_APP_EVENT_MANAGER_URL
		);
		console.log(
			"REACT_APP_EVENT_MANAGER_ENDPOINT : " + REACT_APP_EVENT_MANAGER_ENDPOINT
		);

		// scenarios 1: no error (HTTP status code 200), student's first time login [x]
		// scenarios 2: no error (HTTP status code 200), student's second/more time login [x]
		// scenarios 3: error (HTTP status code 400: Invalid argument (invalid request payload)), student's second/more time login, user data and access code doesn't match
		// scenarios 4: error (HTTP status code 500), service error
		// Handle your various result scenarios here...
		return request.postWithBody(this).then((result) => result);
	}
}
