import React from "react";

const textStyles: React.CSSProperties = {
	color: "#333333",
	fontSize: "1rem",
	fontFamily: `"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
	fontWeight: 400,
	lineHeight: 1.6,
	maxWidth: "486px", // Make sure text length is less than the HorizontalDivider
	whiteSpace: "normal", // Allow text to wrap to a new line
	wordBreak: "break-word",
};

const titleStyles: React.CSSProperties = {
	color: "#030303",
	fontSize: "24px",
	fontFamily: "sans-serif",
	fontWeight: 600,
	lineHeight: "31px",
};
const textTitleStyles: React.CSSProperties = {};

export const TextBody = ({ text }: { text: string }): JSX.Element => {
	return <div style={textStyles}>{text}</div>;
};

export const TextTitle = ({ text }: { text: string }): JSX.Element => {
	return <div style={titleStyles}>{text}</div>;
};

interface TextProps {
	title: string;
	description: string;
}

export const labTitleProps: TextProps = {
	title: "Title",
	description: "",
};

export const labDurationProps: TextProps = {
	title: "Duration",
	description: "",
};

export const labInstructionsProps: TextProps = {
	title: "Instructions",
	description:
		"Press the button to access your Heropa VM. \n You can use this page to launch your environment each day of class. \n If you exit Heropa, you should launch and log in to your VM through this page.",
};

export const progress = "Progress";

// export const contentSections = [
// 	{
// 		title: labModuleProps.title,
// 		description: labModuleProps.description,
// 	},
// 	{
// 		title: labDurationProps.title,
// 		description: labDurationProps.description,
// 	},
// 	{
// 		title: labInstructionsProps.title,
// 		description: labInstructionsProps.description,
// 	},
// ];
