import { IError } from "../actions/labActions";

export class CustomError extends Error implements IError {
  code?: string;
  stack?: string;
  details?: any;

  constructor(message: string, code?: string, details?: any) {
      super(message);
      this.name = 'CustomError'; // setting the name of the error to CustomError
      this.code = code;
      this.details = details;
  }
}
