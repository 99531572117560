// components/LabContent.tsx
import Stack from "@mui/material/Stack";
import React from "react";
import {
	TextBody,
	TextTitle,
	labTitleProps,
	labDurationProps,
	labInstructionsProps,
	progress,
} from "./contents/Text";
import LinearDeterminate from "./contents/CustomizedProgressBars";
import Grid from "@mui/material/Grid";
import StudyImage from "./contents/StudyImage";

import {
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemIcon,
	Link,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

const labContentStyles: React.CSSProperties = {
	height: "500px",
	paddingLeft: "2rem",
	paddingTop: "2rem",
};

const CicLabContent = (): JSX.Element => {
	const title = "Auth0 Labs at Oktane";

	return (
		<div style={labContentStyles}>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="flex-start"
						spacing={3}
					>
						{labTitle(title)}
						{cicLabInstructions()}
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<StudyImage />
				</Grid>
			</Grid>
		</div>
	);
};

export default CicLabContent;


const labTitle = (title: string): JSX.Element => {
	return (
		<>
			<TextTitle text={labTitleProps.title} />
			<TextBody text={title} />
			<HorizontalDivider />
		</>
	);
};
 
const cicLabInstructions = (): JSX.Element => {
	return (
		<>
			<TextTitle text={labInstructionsProps.title} />
			<TextBody
				text={`Select your lab from the list below to get directed to the appropriate code repository on GitHub. Follow the instructions in the repository’s README file to get started.`}
			/>
			<nav aria-label="main mailbox folders">
				<List dense={true}>
					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href="https://github.com/auth0-training/labs-node-working-with-connections/tree/oktane-passkeys"
							target="_blank"
						>
							<ListItemIcon>
								<LinkIcon />
							</ListItemIcon>
							<ListItemText
								primary={`Passwords Are So Passé: Secure Your App with Passkeys`}
								// sx={{ color: `#1e88e5`, display: 'inline' }}
							/>
						</ListItemButton>
					</ListItem>

					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href="https://github.com/auth0-training/labs-node-working-with-okta-fga/tree/oktane"
							target="_blank"
						>
							<ListItemIcon>
								<LinkIcon />
							</ListItemIcon>
							<ListItemText
								primary={`FGA FTW: Implement Fine Grained Authorization for Next-Level Security`}
							/>
						</ListItemButton>
					</ListItem>

					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href="https://github.com/auth0-training/labs-node-working-with-organizations/tree/oktane"
							target="_blank"
						>
							<ListItemIcon>
								<LinkIcon />
							</ListItemIcon>
							<ListItemText
								primary={`Manage B2B Users and Applications with Auth0 Organizations`}
							/>
						</ListItemButton>
					</ListItem>

					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href="https://github.com/auth0-training/labs-node-working-with-actions/tree/oktane"
							target="_blank"
						>
							<ListItemIcon>
								<LinkIcon />
							</ListItemIcon>
							<ListItemText
								primary={`Extend Auth0 Capabilities with Actions`}
							/>
						</ListItemButton>
					</ListItem>

					<ListItem disablePadding>
						<ListItemButton
							component="a"
							href="https://github.com/auth0-training/labs-node-working-with-forms/tree/oktane"
							target="_blank"
						>
							<ListItemIcon>
								<LinkIcon />
							</ListItemIcon>
							<ListItemText
								primary={`Extend Auth0 Identity Flows with Forms`}
							/>
						</ListItemButton>
					</ListItem>
				</List>

				<div>
					<Grid
						container
						direction="column"
						justifyContent="space-around"
						alignItems="left"
						paddingTop={`1.5rem`}
					>
		<Grid item xs={4}>
							<Link
								component="a"
								href="https://auth0-training.github.io/"
								target="_blank"
							>
								Auth0 Labs Troubleshooting FAQs
							</Link>
						</Grid>
						<Grid item xs={4}>
							<TextBody
								text={`Need help? See solutions to common problems experienced across all Auth0 labs here!`}
							/>
						</Grid>
					</Grid>
 
				</div>
			</nav>
		</>
	);
};

const horizontalDividerStyles = {
	top: "457px",
	left: "151px",
	width: "486px",
	height: "1px",
	backgroundColor: "#d3d3d3",
	borderRadius: "2px",
};

const HorizontalDivider = () => {
	return <div style={horizontalDividerStyles} />;
};
