import React from "react";
import { Typography } from "@mui/material";

const logInStyles: React.CSSProperties = {
	color: "#080a0b",
	fontSize: "36px",
	fontFamily: "sans-serif",
	fontWeight: 700,
	// textAlign: "center",
};

const divStyles: React.CSSProperties = {
	width: "350px",
};

const LogInHeader = () => {
	return (
		<div>
			<h1>Welcome</h1>
			<div style={divStyles}>
				<Typography>
					Enter your login details and access code to launch your lab
					environment.
				</Typography>
			</div>
		</div>
	);
};

export default LogInHeader;
