// redux/actions/labActions.ts

import {
	ACCESSCODE_VERIFY_FAILED,
	ACCESSCODE_VERIFY_SUCCEED,
	GET_LAB_CONTENT_DATA,
	LAB_CONTENT_DATA_ACQUIRED,
	LOG_OUT,
	REQUEST_FAILED,
	USER_LOG_OUT,
	VERIFY_ACCESS_CODE_WITH_STUDENT_INFO,
} from "./AppActionTypes";

export interface GetLabContentDataAction {
	type: typeof GET_LAB_CONTENT_DATA;
	item: any;
}

export const getLabContentData = (item: any): GetLabContentDataAction => ({
	type: GET_LAB_CONTENT_DATA,
	item,
});

export interface VerifyAccessCodeWithStudentInfoAction {
	type: typeof VERIFY_ACCESS_CODE_WITH_STUDENT_INFO;
	item: any;
}

export const verifyAccessCodeWithStudentInfo = (
	item: any
): VerifyAccessCodeWithStudentInfoAction => ({
	type: VERIFY_ACCESS_CODE_WITH_STUDENT_INFO,
	item,
});

export interface UpdateLabContentAction {
	type: typeof LAB_CONTENT_DATA_ACQUIRED;
	item: any;
}

export const updateLabContent = (item: any): UpdateLabContentAction => ({
	type: LAB_CONTENT_DATA_ACQUIRED,
	item,
});

export const verifySucceed = (item?: boolean) => ({
	type: ACCESSCODE_VERIFY_SUCCEED,
	item,
});

export const verifyFailed = (item?: boolean) => ({
	type: ACCESSCODE_VERIFY_FAILED,
	item,
});

export const requestFailed = (error?: IError) => ({
	type: REQUEST_FAILED,
	error,
});

export interface UserLogOutAction {
	type: typeof USER_LOG_OUT;
}

export const logUserOut = (): UserLogOutAction => ({
	type: USER_LOG_OUT,
});

export interface LogOutAction {
	type: typeof LOG_OUT;
}

export const logOut = (): LogOutAction => ({
	type: LOG_OUT,
});

export interface IError {
	message: string; // A human-readable description of the error
	code?: string; // An error code that categorizes the error
	stack?: string; // The stack trace (mostly for logging and debugging)
	details?: any; // Any additional properties/details about the error
}
