import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const styles = {
	Text: {
		color: "#000000",
		fontSize: "15px",
		fontFamily: "sans-serif",
		fontWeight: 600,
		lineHeight: "21px",
	},
};

const defaultProps = {
	text: "Privacy Policy",
};

const PrivacyPolicy = () => {
	return (
		<div style={styles.Text}>
			<Link
				to="https://www.okta.com/privacy-policy/"
				style={{ textDecoration: "none", color: "inherit" }}
			>
				{defaultProps.text}
			</Link>
		</div>
	);
};

export default PrivacyPolicy;
