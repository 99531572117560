import React from "react";
import styled from "styled-components";
import companyLogo from "../assets/company-logo.png";

interface HeaderProps {
	style: React.CSSProperties; // Define a type of 'style' prop
}

const Logo = styled.img`
	width: auto;
	height: 120px;
	margin-right: 1rem; /* Add margin to the right for some spacing */
`;

const Header: React.FC<HeaderProps> = ({ style }) => {
	return (
		<div style={style}>
			<Logo src={companyLogo} alt="Company Logo" />
		</div>
	);
};

export default Header;
