import React, { useState, useEffect } from "react";
import img1 from "../assets/okta-image1.jpg";
import img2 from "../assets/okta-image2.jpg"; // Import the new images
import img3 from "../assets/okta-image3.jpg";
import img4 from "../assets/okta-image4.jpg";
import img5 from "../assets/okta-image5.jpg"; // Import the new images
import img6 from "../assets/okta-image6.jpg";
import img7 from "../assets/okta-image7.jpg";
import img8 from "../assets/okta-image8.jpg";
import img9 from "../assets/okta-image9.jpg";

const containerStyles: React.CSSProperties = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100%", // Take the full height of the parent container
};

const imageStyles: React.CSSProperties = {
	width: "90%",
	height: "90%",
	objectFit: "cover",
	objectPosition: "center", // Center the image within its fixed dimensions
	borderRadius: "10px",
};

const OktaBranding = (): JSX.Element => {
	const [imgIndex, setImgIndex] = useState(0); // State to hold the current index of img

	// Array holding all the images
	const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

	useEffect(() => {
		const interval = setInterval(() => {
			setImgIndex((prevIndex: number) => (prevIndex + 1) % images.length); // rotating the image index every 3 seconds.
		}, 3000);
		return () => clearInterval(interval); // Clear the interval while unmounting component
	}, []);

	return (
		<div style={containerStyles}>
			<img src={images[imgIndex]} style={imageStyles} />
		</div>
	);
};

export default OktaBranding;

