import * as React from "react";
import Stack from "@mui/material/Stack";
import LaunchButton from "./LaunchButton";

interface LaunchButtonGroupProps {
	workspaceURL: string;
}

export default function LaunchButtonGroup({
	workspaceURL,
}: LaunchButtonGroupProps) {
	const oktaInstanceLabel = "Launch to Okta Instance";
	const HeropaVMLabel = "Launch to VM";
	const handleClick = () => {
		window.open(workspaceURL, "_blank"); // opens the URL in new tab
	};

	return (
		<div>
			<Stack direction="row" spacing={3}>
				<LaunchButton label={HeropaVMLabel} onClick={handleClick} />
				{/* <LaunchButton label={oktaInstanceLabel} /> */}
			</Stack>
		</div>
	);
}
