// components/LabContent.tsx
import Stack from "@mui/material/Stack";
import React from "react";
import {
	TextBody,
	TextTitle,
	labTitleProps,
	labDurationProps,
	labInstructionsProps,
	progress,
} from "./contents/Text";
import LinearDeterminate from "./contents/CustomizedProgressBars";
import LaunchDescription from "./contents/LaunchDescription";
import LaunchButtonGroup from "./LaunchButtonGroup";
import Grid from "@mui/material/Grid";
import StudyImage from "./contents/StudyImage";
import { ContentCardProps } from "./ContentCard";

const labContentStyles: React.CSSProperties = {
	height: "500px",
	paddingLeft: "2rem",
	paddingTop: "2rem",
};
const textTitleStyles: React.CSSProperties = {
	maxWidth: "486px", // Matches the width of your HorizontalDivider
	overflow: "hidden", // Prevents content extending beyond maxWidth
	textOverflow: "ellipsis", // For long text, cuts it off and adds '...' at the end
	whiteSpace: "nowrap", // Prevents text from wrapping to next line
};

const LabContent = ({ lab, workspaceURL }: ContentCardProps): JSX.Element => {
	const title = lab?.title ? lab.title : labTitleProps.description;
	const startTime = lab?.startTime ? lab.startTime : "";
	const endTime = lab?.endTime ? lab.endTime : "";

	return (
		<div style={labContentStyles}>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="flex-start"
						spacing={3}
					>
						{labTitle(title)}
						{labDuration(startTime, endTime)}
						{labInstructions()}
					</Stack>
				</Grid>
				<Grid item xs={6}>
					<StudyImage />
				</Grid>
			</Grid>

			<div style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
				<LaunchDescription />
			</div>
			<LaunchButtonGroup workspaceURL={workspaceURL} />
		</div>
	);
};

export default LabContent;

const linearDeterminate = (): JSX.Element => {
	return (
		<>
			<TextTitle text={progress} />
			<LinearDeterminate />
			<HorizontalDivider />
		</>
	);
};

const labTitle = (title: string): JSX.Element => {
	return (
		<>
			<TextTitle text={labTitleProps.title} />
			<TextBody text={title} />
			<HorizontalDivider />
		</>
	);
};

const labDuration = (startTime: string, endTime: string): JSX.Element => {
	return (
		<>
			<TextTitle text={labDurationProps.title} />
			<TextBody text={`Start Time: ${startTime}`} />
			<TextBody text={`End Time: ${endTime}`} />
			<HorizontalDivider />
		</>
	);
};

const labInstructions = (): JSX.Element => {
	return (
		<>
			<TextTitle text={labInstructionsProps.title} />
			<TextBody text={labInstructionsProps.description} />
			<HorizontalDivider />
		</>
	);
};

const horizontalDividerStyles = {
	top: "457px",
	left: "151px",
	width: "486px",
	height: "1px",
	backgroundColor: "#d3d3d3",
	borderRadius: "2px",
};

const HorizontalDivider = () => {
	return <div style={horizontalDividerStyles} />;
};
