import React from "react";
import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
import { LabLandingPage } from "./LabLandingPage";
import { AccessCodeLogInPage } from "./AccessCodeLogInPage";
import { LabInfoState } from "../redux/reducer/LabReducer";
import { ILabInfo, IUserInfo } from "../models/ILabInfo";
import { LoadingStatus, Loadable } from "../utils/Loadable";
import { bindActionCreators } from "redux";
import { logUserOut } from "../redux/actions/labActions";

interface LabPropsFromReduxState {
	loggedIn: boolean;
	user: Loadable<IUserInfo>;
	labInfo: Loadable<ILabInfo>;
}

interface ReduxState {
	lab: LabPropsFromReduxState;
}

export type LabComponentProps = LabPropsFromReduxDispatch &
	LabPropsFromReduxState;

export class Labs extends React.PureComponent<LabComponentProps> {
	public render() {
		const { loggedIn } = this.props;

		return (
			<div style={{ minWidth: "1300px" }}>
				{loggedIn ? this.getLabLandingPage() : this.getAccessCodeLogInPage()}
			</div>
		);
	}

	private getAccessCodeLogInPage(): JSX.Element {
		return <AccessCodeLogInPage />;
	}

	private getLabLandingPage(): JSX.Element {
		return (
			<LabLandingPage
				user={this.props.user}
				lab={this.props.labInfo}
				logOut={this.props.logOut}
			/>
		);
	}
}

const mapStateToProps: MapStateToProps<
	LabPropsFromReduxState,
	{},
	ReduxState
> = (state) => ({
	loggedIn: state.lab.loggedIn,
	user: state.lab.user,
	labInfo: state.lab.labInfo,
});

const mapDispatchToProps: MapDispatchToProps<LabPropsFromReduxDispatch, {}> = (
	dispatch
) =>
	bindActionCreators(
		{
			logOut: logUserOut, // the action you want to dispatch
		},
		dispatch
	);

export interface LabPropsFromReduxDispatch {
	readonly logOut: () => void;
}

export const ConnectedLabs = connect<
	LabPropsFromReduxState,
	LabPropsFromReduxDispatch,
	{}, // no ownProps for this
	ReduxState
>(
	mapStateToProps,
	mapDispatchToProps
)(Labs);
