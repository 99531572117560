// Lab Action Types
export const GET_LAB_CONTENT_DATA = "GET_LAB_CONTENT_DATA";

export const VERIFY_ACCESS_CODE_WITH_STUDENT_INFO =
	"VERIFY_ACCESS_CODE_WITH_STUDENT_INFO";

export const LAB_CONTENT_DATA_ACQUIRED = "LAB_CONTENT_DATA_ACQUIRED";

export const ACCESSCODE_VERIFY_SUCCEED = "ACCESSCODE_VERIFY_SUCCEED";

export const ACCESSCODE_VERIFY_FAILED = "ACCESSCODE_VERIFY_FAILED";

export const REQUEST_FAILED = "REQUEST_FAILED";

export const USER_LOG_OUT = "USER_LOG_OUT";

export const LOG_OUT = "LOG_OUT";

// Alert Actions Types
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
