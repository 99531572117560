import React from "react";
import { Provider } from "react-redux";

import { Routes, Route } from "react-router-dom";
import reduxStore from "./redux/store";
import GlobalStyles from "./styles/GlobalStyles";
import { ConnectedLabs } from "./pages/Labs";
import GlobalAlert from "./components/util/Alert";
import { AccessCodeLogInPage } from "./pages/AccessCodeLogInPage";

class App extends React.PureComponent {
	render() {
		return (
			<Provider store={reduxStore}>
				<GlobalStyles />
				<GlobalAlert /> 
				<Routes>
					<Route path="/" element={<ConnectedLabs />} />
					<Route path="/login" element={<AccessCodeLogInPage />} /> 
					// Define other routes as needed
				</Routes>
			</Provider>
		);
	}
}

export default App;
