import React from "react";

const wrapperStyles: React.CSSProperties = {
	paddingLeft: "2rem",
	paddingTop: "3rem",
	paddingBottom: "2rem",
};

const descriptionStyles: React.CSSProperties = {
  color: "#000000",
  fontSize: "18px",
  fontFamily: "sans-serif",
  lineHeight: "26px",
  whiteSpace: 'pre-line'
};

const defaultProps = {
	text: "Read below information and start your hands-on lab.",
};

const DescriptionArea = (): JSX.Element => {
	return (
		<div style={wrapperStyles}>
			<Description />
		</div>
	);
};

const Description = () => {
  return <div style={descriptionStyles}>{defaultProps.text}</div>;
};
export default DescriptionArea;
