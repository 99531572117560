import React from "react";
import studyImage from "../../assets/education-image.jpeg";
import img4 from "../../assets/okta-image4.jpg";

const styles: React.CSSProperties = {
	width: "500px", // Set the desired width
	height: "500px", // Set the desired height
	borderRadius: "100px",
};

const imageStyles: React.CSSProperties = {
	width: "90%",
	height: "90%",
	objectFit: "cover",
	objectPosition: "center", // Center the image within its fixed dimensions
	borderRadius: "10px",
};

const StudyImage = () => {
	return (
		<div style={styles}>
			<img
				src={img4}
				style={imageStyles}
			/>
		</div>
	);
};

export default StudyImage;
