import { HIDE_ALERT, SHOW_ALERT } from "../actions/AppActionTypes";

const initialState: AlertState = {
	open: false,
	message: "",
};

interface AlertState {
	open: boolean;
	message: string;
	severity?: string;
}

const AlertReducer = (state = initialState, action: any) => {

	switch (action.type) {
		case SHOW_ALERT:
			return {
				...state,
				open: true,
				message: action.payload.message,
				severity: action.payload.severity,
			};
		case HIDE_ALERT:
			return {
				...state,
				open: false,
				message: "",
			};
		default:
			return state;
	}
};

export default AlertReducer;
