import { Button, ThemeProvider } from "@mui/material";
import React from "react";
import { theme } from "../pages/LabLandingPage";

const LaunchButtonGroup = (props: LaunchButtonProps): JSX.Element => {
	return (
		<ThemeProvider theme={theme}>
			<Button
				disabled={false}
				size="large"
				variant="contained"
				onClick={props.onClick}
				style={{ textTransform: "none" }}
			>
				{props.label}
			</Button>
		</ThemeProvider>
	);
};

export default LaunchButtonGroup;

interface LaunchButtonProps {
	label: string;
	onClick: () => void; // Add this line
}
