// redux/store.ts
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import createSagaMiddleware from "redux-saga";
import rootLabSaga from "./saga/LabSagas";
import LabReducer, { LabState } from "./reducer/LabReducer";
import AlertReducer from "./reducer/AlertReducer";
import { stat } from "fs";
import { ILabInfo } from "../models/ILabInfo";
import { Loadable, LoadingStatus } from "../utils/Loadable";
import { useDispatch } from "react-redux";
import { logOut, verifyAccessCodeWithStudentInfo } from "./actions/labActions";
import { ILoginInfo } from "./serverApi/requests/VerifyAccessCodeWithStudentInfoRequest";
import { IEventDetails, verifyAccessCodeRequest } from "./serverApi/APIClient";
import { LOG_OUT } from "./actions/AppActionTypes";
import { resetState } from "./actions/GlobalActions";
import { useNavigate } from "react-router-dom";

const ScheduleStorageClearing = (
	eventEndTimeString: string,
	timeZone: string
) => {
	
	// Assume eventEndTimeString is dynamically received and might look like below for the given event from Heropa:
	// "05/15/2024 - 06:00 PM PDT" or "05/15/2024 - 06:00 PM America/New_York"

	// Extracting the timezone from the string if it follows the last space or predefined format
	const lastSpaceIndex = eventEndTimeString.lastIndexOf(" ");
	const dateTimePart = eventEndTimeString.substring(0, lastSpaceIndex);
 
	// Parse the date-time string with timezone
	const eventEndTime = moment.tz(
		dateTimePart,
		"MM/DD/YYYY - hh:mm A",
		timeZone
	);

	// Get the universal time
	const eventEndTimeUTC = eventEndTime.utc();

	// Current time in UTC
	const nowUTC = moment.utc();

	// Calculate delay in milliseconds
	const delay = eventEndTimeUTC.diff(nowUTC);

	if (delay > 0) {
		setTimeout(() => {
			localStorage.removeItem("labState"); // Clear specific state part
			console.log("LocalStorage cleared after the event ended.");
			store.dispatch(resetState());
			window.location.reload();
			window.location.href = '/';
		}, delay);
	} else {
		console.log("Event has already ended.");
		localStorage.removeItem("labState");
		store.dispatch(resetState()); 
		window.location.href = '/login';
	}
};

interface IFetchedLabInfo {
	startTime?: string;
	endTime?: string;
	timeZone?: string;
}

const ScheduleStorageClearingWithStateInLocalStorage = (
	labStateFromLocalStorage: Loadable<ILabInfo>
) => {
	if (
		labStateFromLocalStorage.value?.endTime &&
		labStateFromLocalStorage.value?.timeZone
	) {
		ScheduleStorageClearing(
			labStateFromLocalStorage.value.endTime,
			labStateFromLocalStorage.value.timeZone
		);
	}
};

// load the state from localStorage
const LoadState = () => {
	try {
		const serializedState = localStorage.getItem("labState");
		if (serializedState === null) {
			return undefined; // No state in localStorage, return undefined to use the initial state
		}
		const { state, time } = JSON.parse(serializedState);

		const loginInfo: ILoginInfo = {
			firstName: state.lab.user.value.firstName,
			lastName: state.lab.user.value.lastName,
			emailAddress: state.lab.user.value.email,
			accesscode: state.lab.user.value.accessCode,
		};
		const labStateFromLocalStorage: Loadable<ILabInfo> = state.lab.labInfo;

		verifyAccessCodeRequest(loginInfo)
			.then((seatData: IEventDetails) => {
				const fetchedLabInfo: IFetchedLabInfo = {
					startTime: seatData.details?.startTime,
					endTime: seatData.details?.endTime,
					timeZone: seatData.details?.timeZone,
				};
				
				if (
					fetchedLabInfo &&
					fetchedLabInfo.endTime &&
					fetchedLabInfo.timeZone &&
					(fetchedLabInfo.endTime !== labStateFromLocalStorage.value?.endTime ||
						fetchedLabInfo.timeZone !==
							labStateFromLocalStorage.value?.timeZone)
				) {

					// New end time fetched based on backend data
					ScheduleStorageClearing(
						fetchedLabInfo.endTime,
						fetchedLabInfo.timeZone
					);
				} else {
					// New end time fetched based on backend data
					ScheduleStorageClearingWithStateInLocalStorage(
						labStateFromLocalStorage
					);
				}
			})
			.catch((error: any) => {
				console.error("Error fetching seat data:", error);
				ScheduleStorageClearingWithStateInLocalStorage(
					labStateFromLocalStorage
				);
			});

		return state;
	} catch (error) {
		return undefined; // Handle errors on retrieving or parsing the stored state
	}
};
// Partial state for saving to localStorage
interface SavedState {
	lab: LabState; // Only include lab part
}

// Helper function to save the state to localStorage
const SaveState = (state: SavedState) => {
	try {
		const serializedState = JSON.stringify({
			state,
			time: new Date().getTime(),
		});
		localStorage.setItem("labState", serializedState);
	} catch (error) {
		console.error("Failed to save state:", error);
	}
};

const rootReducer = combineReducers({
	lab: LabReducer,
	alert: AlertReducer,
	// other reducers when needs to be added...
});

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const persistedState = LoadState(); // Load the state from localStorage

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: false,
			immutableCheck: false,
			serializableCheck: false,
		}).concat(sagaMiddleware),
	preloadedState: persistedState, // Use loaded state as initial state
});

// Subscribe to store changes and save only specific parts of the state
store.subscribe(() => {
	const currentState = store.getState(); // Get the current full state
	SaveState({
		lab: currentState.lab, // Save only the lab part of the state
	});
});

// then run the saga
sagaMiddleware.run(rootLabSaga);

export default store;
export type RootState = ReturnType<typeof rootReducer>;
