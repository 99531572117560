// redux/reducer/LabReducer.ts

import { LoadingStatus, Loadable } from "../../utils/Loadable";
import {
	ACCESSCODE_VERIFY_FAILED,
	ACCESSCODE_VERIFY_SUCCEED,
	GET_LAB_CONTENT_DATA,
	LAB_CONTENT_DATA_ACQUIRED,
	LOG_OUT,
} from "../actions/AppActionTypes";
import { RESET_STATE } from "../actions/GlobalActions";

export interface LabState {
	loggedIn: boolean;
	user: Loadable<UserState>;
	labInfo: Loadable<LabInfoState>;
	// Add other lab-related state properties
}

interface UserState {
	userId: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	accessCode?: string;
	seatNo: string;
	workspaceURL: string;
	// Add other lab-related state properties
}

interface EnvironmentState {
	// for all envs excluding heropa VM
	type: string;
	accessLink: string;
	name: string;
}

export interface LabInfoState {
	title: string;
	startTime: string;
	endTime: string;
	timeZone: string;
	instruction?: string;
	progress?: string;
	environment?: EnvironmentState[];
	isCiclab: boolean,
	// Add other lab-related state properties
}

const initialState: LabState = {
	loggedIn: false,
	user: { loadingStatus: LoadingStatus.UnLoad },
	labInfo: { loadingStatus: LoadingStatus.UnLoad },
	// Initialize other lab-related state properties
};

function LabReducer(state = initialState, action: any) {
	switch (action.type) {
		case RESET_STATE:
			return initialState;
		case GET_LAB_CONTENT_DATA:
			return { ...state };
		case LAB_CONTENT_DATA_ACQUIRED:
			const curlab: LabInfoState = {
				isCiclab:action.item.isCiclab,
				title: action.item.name,
				startTime: action.item.startTime,
				endTime: action.item.endTime,
				timeZone:action.item.timeZone,
			};
			const curUser: UserState = {
				userId: action.item.userId,
				seatNo: action.item.seatNo,
				firstName: action.item.firstName,
				workspaceURL: action.item.workspaceURL,
				email:action.item.email,
				accessCode:action.item.accessCode,
			};
			return {
				...state,
				user: { loadingStatus: LoadingStatus.Loaded, value: curUser },
				labInfo: { loadingStatus: LoadingStatus.Loaded, value: curlab },
			};
		// return { ...state };
		case ACCESSCODE_VERIFY_SUCCEED || ACCESSCODE_VERIFY_FAILED:
			return { ...state, loggedIn: action.item };
		case LOG_OUT:
			return initialState;
		case "ERROR":
			console.error(action.item);
			return { ...state };
		// other cases...
		default:
			return state;
	}
}

export default LabReducer;
