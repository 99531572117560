import React from "react";

const styles = {
	Text: {
		color: "#000000",
		fontSize: "14px",
		fontFamily: "sans-serif",
		lineHeight: "21px",
	},
};

const getYear = () => {
	const newDate = new Date();
	return newDate.getFullYear();
};

const defaultProps = {
	text: `Copyright © ${getYear()} Okta. All rights reserved.`,
};

const RightsReserved = () => {
	return <div style={styles.Text}>{defaultProps.text}</div>;
};

export default RightsReserved;
