import React from "react";
import { LoginCard } from "../components/LogInItems/LoginCard";
import Header from "../components/Header";

export class AccessCodeLogInPage extends React.PureComponent {
	
	styles = {
		Header: {
			width: "100%",
			height: "115px",
			backgroundColor: "white",
			padding: "1rem 1rem 1rem 2rem",
		},
	};

	public render() {
		return (
			<>
				<div>
				<Header style={this.styles.Header} />
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "start",
						height: "100vh",
						
					}}
				>
					{this.getLoginCard()}
				</div>
			</>
		);
	}

	private getLoginCard(): JSX.Element {
		return <LoginCard />;
	}
}
