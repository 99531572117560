import React from "react";

const textStyles: React.CSSProperties = {
	color: "black",
	fontSize: "28px",
	fontFamily: "sans-serif",
	fontWeight: 600,
	lineHeight: "36px",
};

const wrapperStyles: React.CSSProperties = {
	paddingLeft: "10%",
	paddingTop: "2.5rem",
	paddingBottom: "2.5rem",
};

const defaultProps = {
	text: "Welcome, Student!",
};

interface WelcomeAreaProps {
  firstName: string;
}

const WelcomeArea = ({firstName}: WelcomeAreaProps): JSX.Element => {
	return (
		<div style={wrapperStyles}>
				<WelcomeText firstName={firstName} />
		</div>
	);
};

interface WelcomeTextProps {
	firstName: string;
}

const WelcomeText = ({ firstName }: WelcomeTextProps) => {
return <div style={textStyles}>Welcome, {firstName}!</div>;
};

export default WelcomeArea;
