import { Grid } from "@mui/material";
import React from "react";

import OktaBranding from "../OktaBranding";
import { ConnectedInputForm } from "./InputForm";

const cardStyles: React.CSSProperties = {
	width: "80%",
	minWidth: "1000px", // Set your desired minimum width
	minHeight: "750px",
	height: "80%",
	backgroundColor: "#ffffff",
	borderRadius: "2px",
	// boxShadow: "2px 0px 10px rgba(3,3,3,0.1)",
	display: "flex",
	justifyContent: "center",
	alignItems: "start",
	// marginTop:"2rem"
	paddingTop:"1.5rem"
};

export const LoginCard = (props: any): JSX.Element => {
	return (
		<div style={cardStyles}>
			<Grid
				container
				direction="row"
				justifyContent="space-around"
				alignItems="center" 
			>
				<Grid item xs={4} >
					<ConnectedInputForm />
				</Grid>
				<Grid item xs={8} paddingLeft={7}>
					<OktaBranding />
				</Grid>
			</Grid>
		</div>
	);
};
