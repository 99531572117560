import React from "react";
import Contact from "./Contact";
import Home from "./Home";
import OktaText from "./OktaText";
import PrivacyPolicy from "./PrivacyPolicy";
import RightsReserved from "./RightsReserved";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const footerStyles: React.CSSProperties = {
	bottom: 0,
	left: 0,
	paddingTop: "6rem",
	paddingLeft: "8rem",
	width: "100%", // Use percentage or 'vw' for responsive width
	height: "200px",
	backgroundColor: "#ffffff",
	boxShadow: "2px -2px 10px rgba(3,3,3,0.1)",
	justifyContent: "space-around",
};

const Footer = () => {
	return (
		<div style={footerStyles}>
			<Grid container spacing={2} >
				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					alignItems="flex-start"
					xs={4}
				>
					<Grid item xs={6}>
						<OktaText />
					</Grid>
					<Grid item xs={6}>
						<RightsReserved />
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container rowSpacing={3}>
						<Grid item xs={12}>
							<Home />
						</Grid>
						<Grid item xs={12}>
							<PrivacyPolicy />
						</Grid>
						<Grid item xs={12}>
							<Contact />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Footer;
