import React from "react";

const styles = {
	Text: {
		color: "#000000",
		fontSize: "15px",
		fontFamily: "sans-serif",
		fontWeight: 600,
		lineHeight: "21px",
	},
};

const defaultProps = {
	text: "Contact Training",
};

const Contact = () => {
	return (
		<div style={styles.Text}>
			<a
				href="mailto:training@okta.com?subject=Questions about training&body=Hi there, I would like to ..."
				style={{ textDecoration: "none", color: "inherit" }}
			>
				{defaultProps.text}
			</a>
		</div>
	);
};

export default Contact;
