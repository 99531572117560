import { getCookie, setCookie } from "typescript-cookie";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Stack,
	TextField,
} from "@mui/material";
import React, { useState } from "react";
import LogInHeader from "./LogInHeader";
import {
	accesscodeProps,
	emailAddressProps,
	firstNameProps,
	lastNameProps,
} from "./LogInInputText";
import { ILoginInfo } from "../../redux/serverApi/requests/VerifyAccessCodeWithStudentInfoRequest";
import { bindActionCreators } from "redux";
import { MapDispatchToProps, MapStateToProps, connect } from "react-redux";
import { verifyAccessCodeWithStudentInfo } from "../../redux/actions/labActions";
import { showAlert } from "../../redux/actions/AlertActions";
import { useDispatch } from "react-redux";
import { blue, green, grey } from "@mui/material/colors";
import { LabInfoState } from "../../redux/reducer/LabReducer";
import { Loadable, LoadingStatus } from "../../utils/Loadable";
export type InputFormProps = InputFormPropsFromReduxDispatch &
	InputFormPropsFromReduxState;

const InputForm = (props: InputFormProps) => {
	const dispatch = useDispatch();
	const [firstName, setFirstName] = useState(getCookie("labportal-firstName"));
	const [lastName, setLastName] = useState(getCookie("labportal-lastName"));
	const [email, setEmail] = useState(getCookie("labportal-emailAddress"));
	const [accessCode, setAccessCode] = useState(
		getCookie("labportal-accesscode")
	);
	const [loading, setLoading] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const timer = React.useRef<ReturnType<typeof setTimeout> | undefined>(
		undefined
	);

	const handleLogin = (event: React.FormEvent) => {
		// Prevent the form's default submission behavior
		event.preventDefault();

		if (firstName && lastName && email && accessCode) {
			setCookie("labportal-firstName", firstName, { expires: 6 });
			setCookie("labportal-lastName", lastName, { expires: 6 });
			setCookie("labportal-emailAddress", email, { expires: 6 });
			setCookie("labportal-accesscode", accessCode, { expires: 6 });
			const loginInfo: ILoginInfo = {
				firstName,
				lastName,
				emailAddress: email,
				accesscode: accessCode,
			};

			// pass the login info
			props.validateAccesscode(loginInfo);

			if (!loading) {
				setSuccess(false);
				setLoading(true);

				if (props.labInfo.loadingStatus === LoadingStatus.Loaded) {
					setSuccess(true);
					setLoading(false);
				}

				timer.current = setTimeout(() => {
					setSuccess(false);
					setLoading(false);
				}, 20000);
			}
		} else {
			dispatch(
				showAlert(
					"Make sure you enter first name, last name, email and access code"
				)
			);
		}
	};

	const buttonSx = {
		bgcolor: grey[900], // Default background color
		"&:hover": {
			bgcolor: grey[700], // Hover background color
		},
		fontSize: "12",
		fontFamily: "sans-serif",
		fontWeight: 600,
		borderRadius: "8px",
		color: "white", // Text color
		position: "relative", // For circular progress positioning
	};

	React.useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Grid item paddingBottom={"2rem"}>
				<LogInHeader />
			</Grid>
			<Grid item>
				<Box
					sx={{
						"& .MuiTextField-root": { m: 1, width: "40ch" },
					}}
					component="form"
					autoComplete="on"
					onSubmit={handleLogin}
				>
					<Stack direction="column" spacing={3}>
						<TextField
							id={firstNameProps.label}
							label={firstNameProps.label}
							placeholder={firstNameProps.placeholder}
							required
							size="small"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<TextField
							id={lastNameProps.label}
							label={lastNameProps.label}
							placeholder={lastNameProps.placeholder}
							required
							size="small"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
						<TextField
							id={emailAddressProps.label}
							label={emailAddressProps.label}
							placeholder={emailAddressProps.placeholder}
							required
							size="small"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<TextField
							id={accesscodeProps.label}
							label={accesscodeProps.label}
							placeholder={accesscodeProps.placeholder}
							required
							size="small"
							value={accessCode}
							onChange={(e) => setAccessCode(e.target.value)}
						/>
					</Stack>

					<Grid paddingTop={"1rem"}>
						<Box sx={{ position: "relative" }}>
							<Button
								variant="contained"
								type="submit"
								sx={buttonSx}
								disabled={loading}
								fullWidth
								onClick={(e) => handleLogin(e)}
							>
								Log in
							</Button>
							{loading && (
								<CircularProgress
									size={24}
									sx={{
										color: green[500],
										position: "absolute",
										top: "50%",
										left: "50%",
										marginTop: "-12px",
										marginLeft: "-12px",
									}}
								/>
							)}
						</Box>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
};

// const buttonStyles: React.CSSProperties = {
// 	borderRadius: "8px",
// 	// backgroundColor: "#000000",
// 	color: "#ffffff",
// 	fontSize: "12",
// 	fontFamily: "sans-serif",
// 	fontWeight: 600,
// };

const mapStateToProps: MapStateToProps<
	InputFormPropsFromReduxState,
	{},
	ReduxState
> = (state) => ({
	loggedIn: state.lab.loggedIn,
	labInfo: state.lab.labInfo,
});

const mapDispatchToProps: MapDispatchToProps<
	InputFormPropsFromReduxDispatch,
	{}
> = (dispatch) =>
	bindActionCreators(
		{
			validateAccesscode: verifyAccessCodeWithStudentInfo, // the action you want to dispatch
		},
		dispatch
	);

interface InputFormPropsFromReduxDispatch {
	readonly validateAccesscode: (item: ILoginInfo) => void;
}

interface InputFormPropsFromReduxState {
	loggedIn: boolean;
	labInfo: Loadable<LabInfoState>;
}
interface ReduxState {
	lab: InputFormPropsFromReduxState;
}

export const ConnectedInputForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(InputForm);
