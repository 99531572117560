import { calculateDelayByGivenTimeFromNow } from "../../utils/commonTools";
import { showAlert } from "../actions/AlertActions";
import { IError } from "../actions/labActions";
import { EventManagerClient } from "./EventManagerClient";
import { ILoginInfo } from "./requests/VerifyAccessCodeWithStudentInfoRequest";

export interface IEventDetails {
	details?: IDetails;
	error?: IError;
}

export interface IDetails {
	startTime: string;
	endTime: string;
	timeZone: string;
	name: string;
	workspaceURL: string;
	firstName: string;
	lastName: string;
	email: string;
	accessCode: string;
	userId: string;
	seatNo: string;
	isCiclab: boolean,
}

function mapEventDetails(
	detailedEvent: any,
	accessCode: string,
	ciclab: boolean
) {
	// Extract the detailedEvent object

	const seats = detailedEvent.seats;
	// Find the seat that matches the provided accessCode
	const seat = seats.find((seat: any) => seat.accessCode === accessCode);
	let url = seat.workspaceURL;
	url = url.replace("esri", "heropa");

	// If a corresponding seat is found, return the necessary details
	if (seat) {
		const delay = calculateDelayByGivenTimeFromNow(
			detailedEvent.endTime,
			detailedEvent.timeZone
		);

		const details = {
			startTime: detailedEvent.startTime,
			endTime: detailedEvent.endTime,
			timeZone: detailedEvent.timeZone,
			name: detailedEvent.name,
			workspaceURL: url,
			firstName: seat.firstname,
			lastName: seat.lastname,
			email: seat.email,
			accessCode: seat.accessCode,
			userId: seat.userId,
			seatNo: seat.seatNo,
			isCiclab: ciclab,
		};

		if (delay <= 0) {
			const msg = "Event ended";
			const result: IEventDetails = { details, error: { message: msg } };
			return result;
		}
		const result: IEventDetails = {
			details,
		};
		return result;
	}

	// If no matching seat is found, return object with error message
	const msg = "no matching seat is found";
	const result: IEventDetails = { error: { message: msg } };
	return result;
}

export const verifyAccessCodeRequest = (payload: ILoginInfo) => {
	const ApiClient = new EventManagerClient();

	console.log(ApiClient.baseURL);
	return ApiClient.handleVerifyAccessCodeWithStudentInfoRequest(
		payload.accesscode,
		payload.emailAddress,
		payload.firstName,
		payload.lastName
	).then((response: any) => {
		if (response?.voucherValid) {
			try {
				const ciclab = response?.ciclab;
				const detailedEvent = response?.detailedEvent; // your data
				const accesscode = response?.accessCode;
				return mapEventDetails(detailedEvent, accesscode, ciclab);
			} catch (error) {
				console.error(error); // TODO:  raise internal service error
			}
		}
		return null;
	});
};
