import { SagaIterator } from "redux-saga";

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IEventDetails, verifyAccessCodeRequest } from "../serverApi/APIClient";
import {
	USER_LOG_OUT,
	VERIFY_ACCESS_CODE_WITH_STUDENT_INFO,
} from "../actions/AppActionTypes";
import {
	IError,
	UserLogOutAction,
	VerifyAccessCodeWithStudentInfoAction,
	logOut,
	updateLabContent,
	verifySucceed,
} from "../actions/labActions";
import { showAlert } from "../actions/AlertActions";

// Watcher saga
export default function* rootLabSaga(): SagaIterator {
	yield takeEvery(USER_LOG_OUT, handleUserLogOut);
	yield takeLatest(
		VERIFY_ACCESS_CODE_WITH_STUDENT_INFO,
		handleVerifyAccessCodeWithStudentInfo
	);
}

// Worker saga
function* handleUserLogOut(action: UserLogOutAction) {
	try {
		yield put(logOut());
	} catch (error) {
		yield requestFailed(error);
	}
}

// Worker saga
function* handleVerifyAccessCodeWithStudentInfo(
	action: VerifyAccessCodeWithStudentInfoAction
) {
	try {
		// verify access code when user logs in
		// register the user if a new user
		// or get user and lab data

		const seatData: IEventDetails = yield call(
			verifyAccessCodeRequest,
			action.item
		);

		if (seatData != null && !seatData.error) {
			window.location.href = '/';
			yield put(verifySucceed(true));
			if (
				seatData.details &&
				seatData.details.hasOwnProperty("workspaceURL") &&
				seatData.details.hasOwnProperty("seatNo")
			) {
				yield put(updateLabContent(seatData.details));
			}
		} else if (seatData != null && seatData.error) {
			yield requestFailed(seatData.error);
		}
	} catch (error) {
		// handle error and creat pop up errors
		yield requestFailed(error);
		console.log(error);
	}
}

function* requestFailed(error: any) {
	// console.error("An error occurred:", error);
	let userMessage = "uh..not sure what happened";
	// Optionally, check if error is of a specific type or has certain properties
	if (isIError(error)) {
		// keep below console for debugging
		// console.error(`Error code: ${error.code}`);
		// console.error(`Error message: ${error.message}`);
		// console.error(`Error details: ${JSON.stringify(error.details)}`);
		userMessage = mapErrorMessage(error);
	}

	// Dispatch an alert
	yield put(showAlert(userMessage));
}

function mapErrorMessage(error: IError) {
	// TODO: should always use error code to identify errors, error message should just be passed around
	if (
		error.message === "Failed to fetch" ||
		error.message === "Endpoint not found"
	) {
		return "Internal Service Error";
	}
	if (error.message === "Unauthorized access") {
		return "Double check your access code and email address";
	}
	if (error.message === "Event ended") {
		return "Cannot sign in because the class is over";
	}
	if (error.message === "no matching seat is found") {
		return "Invalid Access Code";
	}
	return "Oops..Something wrong here.";
}

function isIError(error: any): error is IError {
	return "message" in error && (error as IError).message !== undefined;
}
