import React from "react";

const textStyles: React.CSSProperties = {
	color: "#000000",
	fontSize: "18px",
	fontFamily: "sans-serif",
	lineHeight: "26px",
};

const defaultText: string =
	"Ready to start? Launch into your lab environment by clicking below buttons!";

const LaunchDescription = () => {
	return <div style={textStyles}>{defaultText}</div>;
};

export default LaunchDescription;
