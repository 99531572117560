import React from 'react';

const styles = {
  Text: {
    color: '#000000',
    fontSize: '30px',
    fontFamily: "sans-serif",
    fontWeight: 600,
    lineHeight: '36px',
  },
};

const defaultProps = {
  text: 'Okta',
};

const OktaText = () => {
  return (
    <div style={styles.Text}>
      {defaultProps.text}
    </div>
  );
};

export default OktaText;