import { HttpClient } from "../../HttpClient";

export class Request<TParams extends Record<string, unknown>, TResult> {
	path: string;
	params: TParams;

	constructor(path: string, params: TParams) {
		this.path = path;
		this.params = params;
	}

	// Method to send request
	postWithParams = async (client: HttpClient): Promise<TResult> => {
		const data = this.prepareParams(this.params as { [key: string]: any });
		const response = await client.postWithParams(this.path, data); 
		return response as TResult;
	};

	// Method to send request
	postWithBody = async (client: HttpClient): Promise<TResult> => {
    const data = this.params as { [key: string]: any };
    const response = await client.post(this.path, data);
    return response as TResult;
};

	// Method to get request
	getWithParams = async (client: HttpClient): Promise<TResult> => {
		const data = this.prepareParams(this.params as { [key: string]: any });

		const response = await client.getWithParams(this.path, data);
		return response as TResult;
	};

	prepareParams(params: { [key: string]: any }): string {
		const queries = [];
		for (let key in params) {
			let value = this.toString(params[key]);
			if (value != null) {
				queries.push(`${key}=${encodeURIComponent(value)}`);
			}
		}
		return queries.join("&");
	}

	toString(value: any) {
		if (value === null) {
			return null;
		}
		switch (typeof value) {
			case "undefined":
				return null;
			case "boolean":
				return value ? "true" : "false";
			case "string":
				return value;
			default:
				return "" + value;
		}
	}
}
