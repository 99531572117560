export enum LoadingStatus {
  UnLoad,
  Loading,
  Loaded,
  LoadFailed
}

export interface Loadable<T> {
  loadingStatus: LoadingStatus;
  value?: T;
  error?: Error;
}