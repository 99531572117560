import React from "react";


interface TextProps {
	label: string;
	placeholder: string;
}

export const firstNameProps: TextProps = {
	label: "First Name",
	placeholder: "Enter your first name",
};

export const lastNameProps: TextProps = {
	label: "Last Name",
	placeholder: "Enter your last name",
};

export const emailAddressProps: TextProps = {
	label: "Email Address",
	placeholder: "Enter your email address",
};

export const accesscodeProps: TextProps = {
	label: "Access Code",
	placeholder: "Enter your access code",
};

// const labelStyles: React.CSSProperties = {
// 	color: "#080a0b",
// 	fontSize: "12px",
// 	fontWeight: 500,
//     fontFamily: "sans-serif",
// 	lineHeight: "16px",
// };

// export const LoginInputLabel = ({ text }: { text: string }) => {
// 	return <div style={labelStyles}>{text}</div>;
// };

// const inputFieldstyles: React.CSSProperties = {
// 	top: "312px",
// 	left: "846px",
// 	width: "468px",
// 	height: "48px",
// 	padding: "0px 8px",
// 	border: "0",
//     fontFamily: "sans-serif",
// 	boxSizing: "border-box",
// 	borderRadius: "2px",
// 	boxShadow: "2px 2px 4px rgba(3,3,3,0.1)",
// 	backgroundColor: "#ffffff",
// 	color: "#a9a9a9",
// 	fontSize: "14px",
// 	fontWeight: 500,
// 	lineHeight: "18px",
// 	outline: "none",
// };

// export const LoginInputField = ({ text }: { text: string }) => {
// 	return <input style={inputFieldstyles} placeholder={text} />;
// };

export const contentSections = [
	{
		label: firstNameProps.label,
		description: firstNameProps.placeholder,
	},
	{
		label: lastNameProps.label,
		description: lastNameProps.placeholder,
	},
	{
		label: emailAddressProps.label,
		description: emailAddressProps.placeholder,
	},
	{
		label: accesscodeProps.label,
		description: accesscodeProps.placeholder,
	},
];
