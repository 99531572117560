import { IError } from "../redux/actions/labActions";
import { CustomError } from "../redux/serverApi/CustomError";

export class HttpClient {
	baseURL: string;

	constructor(baseURL: string) {
		this.baseURL = baseURL;
	}

	post(endpoint: string, body: Record<string, unknown>): Promise<any> {
		return fetch(`${this.baseURL}${endpoint}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})
			.then(this.processResponse)
			.catch(this.handleError);
	}

	get(endpoint: string): Promise<any> {
		return fetch(`${this.baseURL}${endpoint}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then(this.processResponse)
			.catch(this.handleError);
	}

	getWithParams(endpoint: string, params: string): Promise<any> {
		// Creating a URLSearchParam object from the params to include them in a query string
		return fetch(`${this.baseURL}${endpoint}?${params}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then(this.processResponse)
			.catch(this.handleError);
	}

	processResponse(response: Response): Promise<any> {
		if (response.ok) {
			return response.json();
		}
		if (response.status === 401) {
			throw new CustomError("Unauthorized access", "401");
		}
		if (response.status === 404) {
			throw new CustomError("Endpoint not found", "404");
		}
		// console.log(response)
		throw new Error(response.statusText);
	}

	postWithParams(endpoint: string, params: string): Promise<any> {
		// Creating a URLSearchParam object from the params to include them in a query string

		return fetch(`${this.baseURL}${endpoint}?${params}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then(this.processResponse)
			.catch(this.handleError);
	}

	handleError(error: Error): void {
		console.error(error);
		throw error;
	}
}
