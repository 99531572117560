import { IActionResult } from "../../../httpClient/serverApi/types/IActionResult";
import { Request } from "../../../httpClient/serverApi/requests/Request";
export interface IVerifyAccessCodeWithStudentInfoParams
	extends Record<string, unknown> {
	accesscode: string;
	emailAddress: string;
	firstName: string;
	lastName: string;
}

export interface IVerifyAccessCodeWithStudentInfoResult extends IActionResult {
	seatNo:string;
	accessCode: String;
	firstname:string;
	lastname:string;
	email:string;
	workspaceURL:string;
}


export type ILoginInfo = IVerifyAccessCodeWithStudentInfoParams;

export class VerifyAccessCodeWithStudentInfoRequest extends Request<
	IVerifyAccessCodeWithStudentInfoParams,
	IActionResult
> {
	constructor(params: IVerifyAccessCodeWithStudentInfoParams) {
		super("accesscode/verifyByStudent", params);
	}
}
