import moment from "moment";

export const calculateDelayByGivenTimeFromNow = (
	timeString: string,
	timeZone: string
) => {

	// Extracting the timezone from the string if it follows the last space or predefined format
	const lastSpaceIndex = timeString.lastIndexOf(" ");
	const dateTimePart = timeString.substring(0, lastSpaceIndex);
	// Parse the date-time string with timezone
	const eventEndTime = moment.tz(
		dateTimePart,
		"MM/DD/YYYY - hh:mm A",
		timeZone
	);

	// Get the universal time
	const eventEndTimeUTC = eventEndTime.utc();

	// Current time in UTC
	const nowUTC = moment.utc();

	// Calculate delay in milliseconds
	const delay = eventEndTimeUTC.diff(nowUTC);
  return delay;
};
