import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { hideAlert } from "../../redux/actions/AlertActions";
import { RootState } from "../../redux/store";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GlobalAlert = () => {
	const alert = useSelector((state: RootState) => state.alert);
	const dispatch = useDispatch();

	const handleSnackbarClose = (
		event: React.SyntheticEvent<any, Event> | Event,
		reason: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}
		dispatch(hideAlert());
	};

	const handleAlertClose = (event: React.SyntheticEvent<Element, Event>) => {
		dispatch(hideAlert());
	};

	const severityLevel = alert.severity ? alert.severity : "error";
	return (
		<Snackbar
			open={alert.open}
			autoHideDuration={5000}
			onClose={handleSnackbarClose}
		>
			<Alert onClose={handleAlertClose} severity={severityLevel}>
				{alert.message}
			</Alert>
		</Snackbar>
	);
};

export default GlobalAlert;
