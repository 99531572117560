import React from "react";
import Header from "../components/Header";
import Footer from "../components/footer/Footer";
import ContentCard from "../components/ContentCard";
import WelcomeArea from "../components/WelcomeWrapper";
import { ILabInfo, IUserInfo } from "../models/ILabInfo";
import { Loadable, LoadingStatus } from "../utils/Loadable";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import grey from "@mui/material/colors/grey";
import { ThemeProvider, createTheme } from "@mui/material";
import { LabPropsFromReduxDispatch } from "./Labs";

interface LabLandingPagePropsFromReduxState {
	readonly user: Loadable<IUserInfo>;
	readonly lab: Loadable<ILabInfo>;
}

type LabLandingPageProps = LabLandingPagePropsFromReduxState &
	LabPropsFromReduxDispatch;

const STUDENT = "Student";

export class LabLandingPage extends React.PureComponent<LabLandingPageProps> {
	constructor(props: LabLandingPageProps) {
		super(props);
	}

	handleLogout = (event: any) => {
		// Prevent the form's default submission behavior
		event.preventDefault();

		this.props.logOut();
	};

	public render() {
		if (
			this.props.lab.loadingStatus === LoadingStatus.Loaded &&
			this.props.user.loadingStatus === LoadingStatus.Loaded
		) {
			const lab = this.props.lab.value;
			const user = this.props.user.value;
		}
		const styles = {
			Header: {
				width: "100%",
				height: "115px",
				backgroundColor: "white",
				padding: "1rem 1rem 1rem 2rem",
				boxShadow: "2px -2px 10px rgba(3,3,3,0.1)",
			},
		};
		return (
			<>
				<div>
					<Header style={styles.Header} />
				</div>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="baseline"
				>
					<Grid item xs={10}>
						<WelcomeArea
							firstName={this.props.user.value?.firstName || STUDENT}
						/>
					</Grid>
					<Grid item xs={2}>
						<ThemeProvider theme={theme}>
							<Button
								type="button"
								disabled={false}
								variant="outlined"
								onClick={(e) => this.handleLogout(e)}
								>
								Log Out
							</Button>
						</ThemeProvider>
					</Grid>
				</Grid>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginBottom: "80px",
					}}
				>
					<ContentCard
						lab={this.props.lab.value}
						workspaceURL={this.props.user.value?.workspaceURL || ""}
					/>
				</div>
				<div>
					<Footer />
				</div>
			</>
		);
	}
}

export const theme = createTheme({
	palette: {
		primary: { main: "#424242" },
	},
});
